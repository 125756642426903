<template>
  <v-container
    class="checkout"
    :class="{ 'w-90': $vuetify.breakpoint.lgAndUp }"
  >
    <PaymentStepper :step="2" class="mx-0 mx-sm-auto mt-3 mt-sm-6" />
    <CategoryTitle
      :category="category"
      :showOnMobile="true"
      :centered="$vuetify.breakpoint.xs"
    />
    <ResponseMessage :response="response" />
    <v-row>
      <v-col cols="12" v-if="!cart.user.profile.confirmed">
        <CheckoutConfirmEmail
          :key="cart.refreshTime"
          @sendEmail="sendActivationMail"
        />
      </v-col>
      <v-col cols="12" v-if="profileLevel < minProfileLevel">
        <CheckoutCompleteProfile />
      </v-col>
      <v-col cols="12">
        <CartInfoServiceCard
          :shippingAddress="cart.shippingAddress"
          :editable="false"
          :isCheckout="true"
          class="w-100"
        />
      </v-col>
      <v-col cols="12">
        <CartInfoAddressCard
          :shippingAddress="cart.shippingAddress"
          :editable="editableAddress"
          @edit="openAddressSelector"
          :isCheckout="true"
          class="w-100"
        />
      </v-col>
      <v-col cols="12">
        <CartInfoTimeslotCard
          :shippingAddress="cart.shippingAddress"
          :timeslot="cart.timeslot"
          :editable="editableTimeslot"
          @edit="openTimeslotSelector"
          :isCheckout="true"
          class="w-100 cart-info-timeslot-card"
          :value="timeslot"
        />
      </v-col>
      <v-col cols="12">
        <CartInfoCard
          imgSrc="/img_layout/services_icons/modifica-ordine.svg"
          text="Modifica entro"
          :infoValue="availableTime"
          :editable="false"
          class="w-100 cart-info-timeslot-card"
        />
      </v-col>

      <v-col cols="12">
        <CartInfoCard
          imgSrc="/img_layout/services_icons/sostutivi.svg"
          text="Accetta sostituzioni"
          :infoValue="acceptAlternativesText"
          :editable="cart.shippingAddress.deliveryServiceId != 1"
          class="w-100 cart-info-timeslot-card"
          @edit="goToCart"
        />
      </v-col>
      <v-col cols="12" v-if="packageList && packageList.length > 0">
        <CartInfoCard
          imgSrc="/img_layout/services_icons/imbustamento.svg"
          text="Imbustamento"
          :infoValue="packagingName"
          :editable="true"
          @edit="showSacchetti = !showSacchetti"
          class="w-100 cart-info-timeslot-card"
        />
      </v-col>
      <v-col cols="12" v-show="showSacchetti">
        <PackagesTypeList
          @updatePackageType="updatePackageType"
          :packages="packageList"
        />
      </v-col>
      <v-col cols="12" order="5">
        <div
          class="checkout-actions d-flex justify-space-between align-center flex-column flex-md-row"
        >
          <div
            class="d-flex flex-column flex-md-row mb-2 mb-md-0 w-sm-auto justify-space-between justify-md-start btn-actions-cnt"
          >
            <v-btn
              color="secondary"
              class="mr-0 mr-md-2 mb-2 mb-md-0 rounded-xl add-fav-btn"
              x-large
              depressed
              outlined
              @click="addAllToFavorites"
            >
              <span>{{ $t("checkout.addToListBtn") }}</span>
            </v-btn>
            <v-btn
              color="secondary"
              class="rounded-xl empty-cart-btn"
              x-large
              outlined
              depressed
              @click="emptyCartConfirm"
            >
              <span>{{ $t("checkout.emptyCartBtn") }}</span>
            </v-btn>
          </div>
          <div
            class="d-flex flex-column align-center align-md-end justify-md-end justify-center total-actions-cnt"
          >
            <div class="gross-total py-sm-1">
              <span class="primary--text">Totale:</span>
              <span>
                {{ $n(cart.grossTotal, "currency") }}
              </span>
            </div>
            <div class="gross-total-btn">
              <v-btn
                class="rounded-xl default--text"
                color="primary"
                x-large
                depressed
                :disabled="canNotProceedToPayment"
                :loading="loading"
                @click="goToPayment"
              >
                {{ $t("checkout.goToPaymentBtn") }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" v-if="forgottenProducts">
        <product-list-slider :products="forgottenProducts" />
      </v-col>
      <v-col cols="12">
        <category-block
          :target="category"
          :container="false"
          position="position2"
          class="category-block category-block-2"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.checkout {
  .gross-total {
    text-align: right;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      text-align: center;
      margin: 0px 6px 6px 0px;
    }
    font-size: 28px;
    font-weight: bold;
  }
  .btn-actions-cnt {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      width: 100%;
    }
  }
  .total-actions-cnt {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      width: 100%;
    }
    .gross-total-btn {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        width: 100%;
      }
      text-align: right;
      .v-btn {
        @media #{map-get($display-breakpoints, 'xs-only')} {
          width: 100%;
        }
      }
    }
  }

  .v-stepper {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      max-width: 80%;
    }
  }
  .cart-info-timeslot-card {
    .value {
      text-transform: uppercase;
    }
  }
  .privacy-check {
    a {
      color: var(--v-default-base) !important;
      font-weight: bold;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      .checkout-title {
        h1 {
          font-size: 24px !important;
        }
      }
    }
  }
  .product-price {
    margin: 0 4px;
  }
  .qty-wrap {
    max-width: 160px;
    min-width: 150px;
    height: 50px;
  }
  .checkout-item-list {
    background: transparent;
    .v-subheader {
      background: transparent;
    }
  }
}
</style>
<script>
import PaymentStepper from "@/components/payment/PaymentStepper.vue";
import CheckoutConfirmEmail from "@/components/cart/CheckoutConfirmEmail.vue";
import CheckoutCompleteProfile from "@/components/cart/CheckoutCompleteProfile.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import FastPayment from "@/components/cart/FastPayment.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
import { getPersonInfo } from "~/service/ebsn";
// import AnalyticsService from "~/service/analyticsService";
import ListService from "~/service/listService";
import RegistrationService from "~/service/userService";
import GiftProductSelector from "@/components/gift/GiftProductSelector.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { forEachCartItem } from "~/service/ebsn.js";
import { mapActions, mapState, mapGetters } from "vuex";
//import { mapCartInfo } from "~/service/ebsn";

import get from "lodash/get";
import CartInfoServiceCard from "@/components/cart/CartInfoServiceCard.vue";
import cartService from "~/service/cartService";
import CartInfoCard from "@/components/cart/CartInfoCard.vue";
import PackagesTypeList from "@/components/payment/PackagesTypeList.vue";

export default {
  name: "Checkout",
  mixins: [login, cartInfo, categoryMixins],
  components: {
    PaymentStepper,
    CategoryTitle,
    CheckoutConfirmEmail,
    CheckoutCompleteProfile,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    ResponseMessage,
    CartInfoServiceCard,
    CartInfoCard,
    PackagesTypeList
  },
  data() {
    return {
      error: null,
      loading: false,
      response: {},
      minProfileLevel: global.config.minProfileLevel,
      giftCertificates: [],
      emailKey: 1,
      packages: true,
      selectedOptions: {},
      packagingTypeId: null,
      iosMobile: false,
      showPersonalCoupon: false,
      dialogInstance: null,
      acceptAlternatives: false,
      partialAlternatives: false,
      showSacchetti: false,
      packageList: [],
      forgottenProducts: null // what's this?
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      profileLevel: "cart/profileLevel",
      user: "cart/getUser"
    }),
    /*  ...mapCartInfo({
      packagingTypeId: "package_type"
    }),*/
    canNotProceedToPayment() {
      return (
        this.cart.totalItems == 0 ||
        !this.cart.user.profile.confirmed ||
        this.profileLevel < this.minProfileLevel
      );
    },
    editableAddress() {
      return !(this.cart.cartStatusId == 7);
    },
    editableTimeslot() {
      return !(this.$route.name == "Payment");
    },
    availableTime() {
      let duration = this.$dayjs(
        this.cart.timeslot.cutoffDate + " " + this.cart.timeslot.cutoffTime,
        "DD/MM/YYYY HH:mm"
      ).fromNow(true);
      return duration; //.humanize(true);
    },
    acceptAlternativesText() {
      if (this.cart.shippingAddress.deliveryServiceId == 1) {
        return "Da confermare al ritiro della spesa";
      } else {
        if (this.partialAlternatives) {
          return "Accetta la sostituzione dei prodotti mancanti SOLO per i prodotti selezionati";
        } else if (this.acceptAlternatives) {
          return "Accetta tutte le sostituzioni";
        } else {
          return "NON sostituire i prodotti mancanti";
        }
      }
    },

    packagingName() {
      console.log("ppp", this.packagingTypeId);
      const foundObject = this.packageList.find(
        p => p.productId == this.packagingTypeId
      );

      if (foundObject) {
        return foundObject.name;
      } else {
        return "";
      }
    }
  },
  methods: {
    get: get,
    ...mapActions({
      lockCart: "cart/lockCart",
      emptyCart: "cart/emptyCart",
      removeGiftCode: "cart/removeGiftCode",
      loadCart: "cart/loadCart",
      getCart: "cart/getCart"
    }),
    sendActivationMail() {
      this.response = {};
      let _this = this;
      RegistrationService.registrationResendEmail(this.cart.user.email).then(
        function(data) {
          _this.response = data.response;
        },
        function(response) {
          _this.response = response;
        }
      );
    },
    updatePackageType() {
      this.packagingTypeId = getPersonInfo(this.user.person, 101);
    },
    async loadGiftCertificates() {
      //this.autoAssignedGifts = await userService.listGiftCertificate("auto");

      let giftCertificates = await RegistrationService.listGiftCertificate(
        "list"
      );
      var manualGiftCertificates = await RegistrationService.listGiftCertificate(
        "manual"
      );

      this.giftCertificates = [
        ...giftCertificates,
        ...manualGiftCertificates
      ].filter(giftCertificate => {
        // if (giftCertificate.giftAdjustmentType.giftAdjustmentTypeId == 21) {
        //   //hide refund
        //   return false;
        // }
        //check user gift
        let hasUserGift = [];
        if (giftCertificate.userGiftCertificates?.length > 0) {
          hasUserGift = giftCertificate.userGiftCertificates.filter(
            userGiftCertificate => {
              return !userGiftCertificate.orderId;
            }
          );
        }
        return !!giftCertificate.giftCode || hasUserGift.length > 0;
      });
    },
    async removeUserGiftCode(giftCodeId) {
      let cart = await this.removeGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async getUserDetails() {
      let _this = this;
      const data = await RegistrationService.getUserDetail();
      // merge with used certificates
      _this.giftCertificates = data.giftCertificatesReceived || [];
    },
    async fastPayment() {
      let _this = this;
      let res = await _this.$dialog.show(FastPayment, {
        waitForResult: true,
        width: 500
      });
      if (res) {
        // let items = [];
        // forEachCartItem(_this.cart, function(item) {
        //   items.push(item);
        // });
        // await ListService.addProductsToList(res.listId, items);
      }
    },
    async addAllToFavorites() {
      let _this = this;
      let res = await _this.$dialog.show(ListSelector, {
        waitForResult: true,
        fullscreen: _this.$vuetify.breakpoint.smAndDown,
        width: 300
      });
      if (res) {
        let items = [];
        forEachCartItem(_this.cart, function(item) {
          items.push(item);
        });
        await ListService.addProductsToList(res.listId, items);
      }
    },
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    async goToPayment() {
      let _this = this;
      _this.loading = true;
      try {
        if (await this.needLogin("addtocart")) {
          if (await this.needAddress()) {
            if (await this.needTimeslot()) {
              await _this.lockCart();
              _this.$router.push({
                name: "Payment"
              });
            }
          }
        }
      } catch (err) {
        await this.getCart();
        await this.needTimeslot();
        _this.error = err.message;
      } finally {
        _this.loading = false;
      }
    },
    async goToCart() {
      this.$router.push({
        name: "Cart"
      });
    },
    async showPersonalCouponDialog() {
      this.dialogInstance = await this.$dialog.show(GiftProductSelector, {
        title: "Scegli ii proodtti a cui appliicare i tuoi bolloni",
        waitForResult: false,
        fullscreen: this.$vuetify.breakpoint.smAndDown
      });
      const result = await this.dialogInstance.wait();
      if (result) {
        await this.loadCart();
      }
      return result;
    },
    async fetchPackages() {
      this.packageList = await cartService.getPackages();
      // this.packageList = getPersonInfo(this.user.person, 101);
    }
  },
  async created() {
    let alternatives = [];
    let _this = this;
    await this.fetchPackages();
    this.updatePackageType();
    forEachCartItem(this.cart, function(item) {
      if (
        item.cartItemInfo &&
        item.cartItemInfo.accept_alternatives == "false"
      ) {
        _this.acceptAlternatives = false;
      } else {
        alternatives.push(item);
        if (alternatives.length == _this.cart.totalItems) {
          _this.acceptAlternatives = true;
          _this.partialAlternatives = false;
        } else {
          _this.acceptAlternatives = false;
          _this.partialAlternatives = true;
        }
      }
    });
  }
};
</script>
