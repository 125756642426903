<template>
  <div v-if="packages && packages.length">
    <div class="text-body-2 font-weight-bold text-uppercase">
      {{ $t("checkout.packageAddTitle") }}
    </div>

    <v-radio-group
      class="w-100"
      mandatory
      v-model="packagingTypeId"
      @change="updatePackageInfo"
    >
      <v-radio
        v-for="pck in packages"
        :key="pck.productId"
        :value="pck.productId"
        color="default"
      >
        <template v-slot:label>
          <div class="d-flex align-center flex-row">
            <img
              :src="`/img_layout/package/${pck.productId}.svg`"
              :alt="pck.name"
              width="50"
            />
            <div class="d-flex flex-column align-left ml-2">
              <span class="d-block font-weight-bold text-body-2">{{
                pck.name
              }}</span>
              <span>{{ pck.description }}</span>
            </div>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { getPersonInfo } from "~/service/ebsn";
//import RegistrationService from "~/service/userService";
import UserService from "@/commons/service/userService";
import { mapActions, mapGetters } from "vuex";
//import get from "lodash/get";
export default {
  name: "PackagesTypeList",
  props: {
    packages: { type: Array, required: true }
  },
  data() {
    return {
      billingData: {
        phone: null
      },
      packagingTypeId: null
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    }),

    async updatePackageInfo() {
      await UserService.updatePersonInfo(101, this.packagingTypeId);
      await this.setCartInfo({ ["package_type"]: this.packagingTypeId });
      this.$emit("updatePackageType");
    }
  },

  async mounted() {
    const packageInfo = getPersonInfo(this.user.person, 101);
    if (packageInfo) {
      this.packagingTypeId = Number(packageInfo);
    } else {
      this.packagingTypeId = this.packages && this.packages[0].productId;
    }
  }
};
</script>
